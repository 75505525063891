import React from 'react';
import './styles.scss';
import coinEdge from '../../assets/images/coin.png';
import bearLogo from '../../assets/images/bear-logo.png';
import { useNoise } from '../../components/Header/hooks';

const Header: React.FC<{ coins: number }> = ({ coins = 0 }) => {
  const { showNoise } = useNoise();

  return (
    <header className="app-header">
      <div className="coin-container">
        <div className="coin-container__image">
          <img src={coinEdge} className="coin-container__coin" alt="coin" />
          <div className="coin-container__coin-effect"></div>
        </div>
        <p className="coin-container__count">{coins.toFixed(3)}</p>
      </div>
      <div className="logo">
        <img src={bearLogo} className="logo__image" alt="logo" />
        {showNoise && <div className="logo__noise"></div>}{' '}
      </div>
    </header>
  );
};

export default Header;
