import React, { ReactNode, useEffect } from 'react';
import './styles.scss';
import { checkAuth } from '../../services/auth';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setIsAuth, setToken } from '../../store/authSlice';
import { RootState } from '../../store/store';

interface Props {
  children?: ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const queryToken =
    searchParams.get('token') || window.localStorage.getItem('token');
  const dispatch = useDispatch();

  const storedToken = useSelector((state: RootState) => state.auth.token);
  const isAuth = useSelector((state: RootState) => state.auth.isAuth);
  const error = useSelector((state: RootState) => state.auth.error);

  const { isLoading: isCheckAuthLoading } = useQuery(
    'checkAuth',
    () => checkAuth(storedToken || '') as any,
    {
      onSuccess: () => {
        dispatch(setError(null));
        dispatch(setIsAuth(true));
      },
      onError: (err) => {
        dispatch(setError(err));
        dispatch(setIsAuth(false));
      },
      retryDelay: (attemptIndex) => attemptIndex * 10000,
      enabled: !!storedToken || storedToken === '',
      retry: false,
    },
  );

  useEffect(() => {
    dispatch(setToken(queryToken));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('token', String(storedToken));

    const params = new URLSearchParams(location.search);
    params.set('token', String(storedToken));

    const newPath = location.pathname === '/auth' ? '/' : location.pathname;
    navigate(`${newPath}?${params.toString()}`, { replace: true });
  }, [storedToken, location?.pathname]);

  useEffect(() => {
    if (!isAuth && error) {
      navigate(`/auth?token=${storedToken}`);
    }
  }, [isAuth, error, navigate]);

  return (
    <>
      <Spinner isShow={isCheckAuthLoading && !error} hideBackground />
      {children}
    </>
  );
};

export default AuthProvider;
