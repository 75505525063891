import React, { useState } from 'react';
import './styles.scss';
import AppLayout from '../../components/AppLayout';
import BackButton from '../../components/BackButton';
import walletIcon from '../../assets/images/wallet.png';
import Button from '../../components/Button';

const WalletPage: React.FC = () => {
  const [walletAddress, setWalletAddress] = useState<string | null>(null);

  // Функция для открытия платежа через Telegram WebApp
  const requestPayment = async () => {
    try {
      const tg = (window as any).Telegram?.WebApp;

      // Открытие формы оплаты
      tg?.payments.request({
        price: {
          currency: 'XTR', // валюта для звездочек
          amount: 1, // одна звездочка
        },
        description: 'Оплата 1 звездочки для получения TON монет',
        payload: 'unique_payload_123', // уникальный идентификатор для оплаты
      });
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };

  return (
    <AppLayout>
      <BackButton />
      <main className="wallet__main">
        <div className="wallet__top-wrapper">
          <img src={walletIcon} className="wallet__image" alt="wallet" />
          <h2 className="wallet__title">Connect your wallet for reward</h2>
          {walletAddress ? (
            <p>Wallet Address: {walletAddress}</p>
          ) : (
            <Button className="wallet__button" onClick={requestPayment}>
              Pay 1 Star to get TON coins
            </Button>
          )}
        </div>
      </main>
    </AppLayout>
  );
};

export default WalletPage;
