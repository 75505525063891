import { useEffect } from 'react';

const useTelegramWebApp = () => {
  useEffect(() => {
    if ((window as any).Telegram?.WebApp) {
      const tg = (window as any).Telegram.WebApp;
      tg.expand();
    }
  }, []);
};

export default useTelegramWebApp;
