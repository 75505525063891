// store/rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import coinsReducer from './coinsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  coins: coinsReducer,
});

export default rootReducer;
