import React from 'react';
import './styles.scss';
import AppLayout from '../../components/AppLayout';
import BackButton from '../../components/BackButton';
import { STAGES } from './constants';
import Stage from './components/Stage';
import walletIcon from '../../assets/images/roadmap.png';

const RoadmapPage: React.FC = () => {
  return (
    <AppLayout>
      <BackButton />
      <img src={walletIcon} className="roadmap__image" alt="shop" />
      <main className="roadmap__main">
        <h2 className="roadmap__title">Roadmap</h2>
        <div className="roadmap__bottom-wrapper">
          {STAGES.map((stage) => (
            <Stage
              key={stage.title}
              title={stage.title}
              status={stage.status}
            />
          ))}
        </div>
      </main>
    </AppLayout>
  );
};

export default RoadmapPage;
