import React from 'react';
import './styles.scss';
import AppLayout from '../../components/AppLayout';
import BackButton from '../../components/BackButton';
import friendsImage from '../../assets/images/friends.png';
import coinImage from '../../assets/images/coin.png';
import Button from '../../components/Button';
import InviteStatistics from './components/InviteStatistics';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useQuery } from 'react-query';
import { getCurrentUserInfo } from '../../services/user';
import { getCurrentUserReferalStory } from '../../services/referal';

const InvitePage: React.FC = () => {
  const storedToken = useSelector((state: RootState) => state.auth.token);

  const { data: currentUserInfoData } = useQuery(
    'getCurrentUserInfo',
    getCurrentUserInfo,
    {
      enabled: !!storedToken,
      retry: false,
    },
  );

  const { data: referalStoryData } = useQuery(
    'getCurrentUserReferalsStory',
    getCurrentUserReferalStory,
    {
      enabled: !!storedToken,
      retry: false,
    },
  );

  const handleForwardRefClick = () => {
    const message = `${process.env.REACT_APP_BOT_URL}?start=${currentUserInfoData?.ref}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(message)}`;
    window.open(telegramUrl, '_blank');
  };

  return (
    <AppLayout>
      <BackButton />
      <main className="invite__main">
        <div className="invite__top-wrapper">
          <img
            src={friendsImage}
            className="invite__friends-image"
            alt="coin"
          />
          <h2 className="invite__title">
            Invite Friends and Earn{' '}
            <img
              src={coinImage}
              className="coin-button__collect-icon"
              alt="coin"
            />
          </h2>
          <p className="invite__subtitle">
            For each invited friend you will receive{' '}
            {process.env.REACT_APP_COIN_AMOUNT_PER_FRIEND}
            <img
              src={coinImage}
              className="coin-button__collect-icon"
              alt="coin"
            />
          </p>
          <Button
            onClick={handleForwardRefClick}
            className="invite__main-button"
            disabled={!currentUserInfoData?.ref}
          >
            Invite friends
          </Button>
        </div>
        <div className="invite__bottom-wrapper">
          <p className="invite__info-subtitle">Your Statistics</p>
          <InviteStatistics
            referrals={Number(referalStoryData?.referalsStory?.length) || 0}
            rewards={Number(referalStoryData?.rewards) || 0}
          />
        </div>
      </main>
    </AppLayout>
  );
};

export default InvitePage;
