import { MenuItem } from './types';

import inviteIcon from '../../assets/images/invite.png';
import earnIcon from '../../assets/images/earn.png';
import walletIcon from '../../assets/images/wallet.png';
import shopIcon from '../../assets/images/shop.png';

export const DEFAULT_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Invite',
    icon: inviteIcon,
    url: '/invite',
    id: 0,
  },
  {
    title: 'Earn',
    icon: earnIcon,
    url: '/earn',
    id: 1,
  },
  {
    title: 'Shop',
    icon: shopIcon,
    url: '/shop',
    id: 2,
  },
  {
    title: 'Wallet',
    icon: walletIcon,
    url: '/wallet',
    id: 3,
  },
  // {
  //   title: 'Roadmap',
  //   icon: roadmapIcon,
  //   url: '/roadmap',
  //   id: 4,
  // },
];
