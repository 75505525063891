import React from 'react';
import './styles.scss';

export interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  variant?: 'collect' | 'rectangle' | 'icon';
  vibrateType?: 'light' | 'medium' | 'heavy' | 'rigid' | 'soft' | null;
  children?: React.ReactNode;
  isActive?: boolean;
  icon?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  className,
  variant = 'collect',
  vibrateType = 'heavy',
  children,
  isActive,
  icon,
  ...props
}) => {
  const buttonClass = variant ? `button button-${variant}` : 'button';

  const handleClick = () => {
    if (vibrateType) {
      window?.Telegram?.WebApp?.HapticFeedback?.impactOccurred(vibrateType);
    }
    onClick && onClick();
  };

  if (variant === 'icon') {
    return (
      <button
        className={`${buttonClass} ${className}`}
        disabled={disabled}
        data-active={isActive}
        {...props}
        onClick={handleClick}
      >
        <img src={icon} className={`${buttonClass}__icon`} alt="button-icon" />
        <span>{children}</span>
      </button>
    );
  }

  return (
    <button
      className={`${buttonClass} ${className}`}
      disabled={disabled}
      data-active={isActive}
      {...props}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
