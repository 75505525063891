import React, { ReactNode, useEffect, useState } from 'react';
import './styles.scss';

interface Props {
  children: ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      <div>
        <img
          src={require('../../assets/images/background.webp')}
          alt="Background"
          className="app__background"
          onLoad={() => setIsLoaded(true)}
        />
      </div>
      <div className="app__content" data-loaded={isLoaded}>
        {children}
      </div>
    </>
  );
};

export default AppLayout;
