import axios from './api/axios';
import { AxiosResponse } from 'axios';
import { BoosterData } from '@/types/Shop';

export const getBoosters = async (): Promise<AxiosResponse<BoosterData[]>> => {
  const response = await axios.get<BoosterData[]>('/booster/');
  return response;
};

export const getActiveBoosters = async (): Promise<
  AxiosResponse<BoosterData[]>
> => {
  const response = await axios.get<BoosterData[]>('/booster/active/');
  return response;
};

export const buyBooster = async (
  booster: BoosterData,
): Promise<AxiosResponse<string>> => {
  const response = await axios.post<string>('/booster/purchase/', {
    type: booster.type,
    stage: booster.stage,
  });
  return response;
};
