import axios from './api/axios';
import { AxiosResponse } from 'axios';
import { MissionData } from '@/types/Mission';

export const getEarns = async (): Promise<AxiosResponse<MissionData[]>> => {
  const response = await axios.get<MissionData[]>('/earn/user');
  return response;
};

export const handleMissionClick = async (earnId?: number) => {
  if (!earnId) {
    return;
  }
  const response = await axios.post<MissionData[]>('/earn/', {
    earnId,
  });

  return response;
};
