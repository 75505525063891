import React from 'react';
import './styles.scss';
import { BoosterData, BoosterType } from '../../../../types/Shop';
import { PriceType } from '../../../../types/Coin';
import coinImage from '../../../../assets/images/coin.png';
import TONImage from '../../../../assets/images/TON.png';
import AcceleratorImage from '../../../../assets/images/accelerator-booster.png';
import VolumeImage from '../../../../assets/images/volume-booster.png';
import RobotImage from '../../../../assets/images/robot-booster.png';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

interface Props {
  isOpen: boolean;
  onConfirm: (booster: BoosterData) => void;
  onReject: () => void;
  booster: BoosterData;
}

const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onConfirm,
  onReject,
  booster,
}) => {
  const mainIcon: Record<BoosterType, string> = {
    accelerator: AcceleratorImage,
    volume: VolumeImage,
    robot: RobotImage,
  };

  const priceIcon: Record<PriceType, string> = {
    coins: coinImage,
    TON: TONImage,
  };

  if (isOpen) {
    return (
      <Modal isOpen={isOpen} onClose={onReject} className="modal_booster">
        <h2 className="modal__title">Buy a booster?</h2>
        <span className="modal__booster">
          <div className="booster__description">
            <h3 className="booster__title">{booster.title}</h3>
            <p className="booster__subtitle">{booster.subtitle}</p>
          </div>
        </span>
        <span className="modal__price">
          <img src={priceIcon?.[booster.priceType]} alt="booster-price-icon" />
          <p>{booster.price || 0}</p>
        </span>
        <span className="modal__buttons">
          <Button onClick={onReject}>Cancel</Button>
          <Button onClick={() => onConfirm(booster)}>Ok</Button>
        </span>
      </Modal>
    );
  }
  return null;
};

export default ConfirmModal;
