// App.tsx
import React, { lazy, Suspense } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import AuthProvider from './components/AuthProvider';
import { Provider } from 'react-redux';
import store from './store/store';
import MainPage from './pages/Main';
import InvitePage from './pages/Invite';
import EarnPage from './pages/Earn';
import WalletPage from './pages/Wallet';
import ShopPage from './pages/Shop';
import RoadmapPage from './pages/Roadmap';
import useTelegramWebApp from './hooks/telegram';

const ProfilePage = lazy(() => import('./pages/Profile'));
const AuthPage = lazy(() => import('./pages/AuthPage'));
const NotFoundPage = lazy(() => import('./pages/NotFound'));

const App: React.FC = () => {
  useTelegramWebApp();

  return (
    <Provider store={store}>
      <AuthProvider>
        <Suspense>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/invite" element={<InvitePage />} />
            <Route path="/earn" element={<EarnPage />} />
            <Route path="/wallet" element={<WalletPage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/roadmap" element={<RoadmapPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </AuthProvider>
    </Provider>
  );
};

export default App;
