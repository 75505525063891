import React, { ReactNode } from 'react';
import './styles.scss';
import SuccessImage from '../../../../assets/images/success.png';
import ClosedImage from '../../../../assets/images/closed.png';
import { StageStatus } from '../../constants';

interface Props {
  title: ReactNode;
  status: StageStatus;
}

const Stage: React.FC<Props> = ({ title, status }) => {
  const mainIcon: Record<StageStatus, string> = {
    [StageStatus.complete]: SuccessImage,
    [StageStatus.soon]: ClosedImage,
  };

  return (
    <div className="stage__wrapper">
      <img src={mainIcon?.[status]} className="stage__icon" alt="status-icon" />
      <div className="stage__description">
        <h3 className="stage__title">{title}</h3>
      </div>
    </div>
  );
};

export default Stage;
