import dayjs from 'dayjs';
import { CalculateRemainingTimeReturnData } from '@/pages/Main/components/CoinButton/types';

export const calculateRemainingTime = (
  availableDate?: Date | string,
  maxStorageCoins?: number, // Максимальное количество монет
  coinsPerHour?: number, // Монет в час
): CalculateRemainingTimeReturnData => {
  const now = dayjs();
  const availableDateTime = dayjs(availableDate);

  // Определяем максимальное количество монет, которые можно получить за всё время
  const maxEarnableCoins = maxStorageCoins || 0;

  // Рассчитываем количество монет, которое можно заработать с последней активации
  const secondsPassed = dayjs().diff(availableDateTime, 'second');
  const coinsEarned = Math.min(
    ((coinsPerHour || 0) * secondsPassed) / 60 / 60,
    maxEarnableCoins,
  );

  // Определение оставшегося времени для заполнения "резерва" монет
  const remainingCoins = maxEarnableCoins - coinsEarned;

  // Если накоплено максимальное количество монет, прогресс на 100%
  if (remainingCoins <= 0) {
    return {
      availableDate: new Date(),
      text: '',
      progressPercent: 100,
      coinsFarmedCount: maxEarnableCoins,
    };
  }

  // Рассчитываем оставшееся время до накопления максимального количества монет
  const remainingHours = remainingCoins / (coinsPerHour || 1);

  // Определение текста оставшегося времени
  const diffHours = Math.floor(remainingHours); // Часы
  const diffMinutes = Math.floor((remainingHours % 1) * 60); // Минуты
  const diffSeconds = Math.floor((((remainingHours % 1) * 60) % 1) * 60); // Секунды

  // Определение процента накопления монет
  const progressPercent = (coinsEarned / maxEarnableCoins) * 100;

  // Округление до двух знаков после запятой для процента
  const roundedProgressPercent = Math.round(progressPercent * 100) / 100;

  return {
    availableDate: availableDateTime.toDate(),
    text: `${diffHours}:${String(diffMinutes).padStart(2, '0')}:${String(
      diffSeconds,
    ).padStart(2, '0')}`,
    progressPercent: roundedProgressPercent,
    coinsFarmedCount: coinsEarned,
  };
};
