import React, { useState } from 'react';
import './styles.scss';
import AppLayout from '../../components/AppLayout';
import BackButton from '../../components/BackButton';
import shopImage from '../../assets/images/shop.png';
import coinImage from '../../assets/images/coin.png';
import { PriceType } from '../../types/Coin';
import Spinner from '../../components/Spinner';
import Notification from '../../components/Notification';
import Booster from './components/Booster';
import { BoosterData } from '../../types/Shop';
import { useShopControl } from './hooks';
import ConfirmModal from './components/ConfirmModal';

const ShopPage: React.FC = () => {
  const {
    coins,
    resultSellBoosters,
    selectedBooster,
    handleRejectClick,
    handleConfirmClick,
    handleBoosterClick,
    isBoosterBuyFetching,
    isCoinsStatusLoading,
    isBoostersListLoading,
    isActiveBoostersLoading,
    isCoinsStatusError,
    isBoostersListError,
    coinsStatusError,
    boostersListStatusError,
  } = useShopControl();

  return (
    <AppLayout>
      <BackButton />
      <main className="shop__main">
        <div className="shop__top-wrapper">
          <img src={shopImage} className="shop__image" alt="shop" />
          <h2 className="shop__title">Bear shop</h2>
          <div className="shop__coins-status">
            <p className="shop__subtitle">Current Balance</p>
            <span>
              <img src={coinImage} className="shop__coin-icon" alt="coin" />
              {coins?.toFixed(3) || 0}
            </span>
          </div>
        </div>
        <div className="shop__boosters-wrapper">
          {resultSellBoosters?.length > 0 ? (
            resultSellBoosters?.map((booster: BoosterData) => (
              <Booster
                onClick={handleBoosterClick}
                booster={booster}
                key={booster.id}
                disabled={
                  isBoosterBuyFetching ||
                  booster?.isLastStage ||
                  (booster.priceType as PriceType) === 'TON' ||
                  ((booster.priceType as PriceType) === 'coins' &&
                    booster.price > coins)
                }
              />
            ))
          ) : (
            <p className="shop__not-boosters">
              Have you bought all the boosters
            </p>
          )}
        </div>
      </main>
      <Spinner
        isShow={
          isCoinsStatusLoading ||
          isBoostersListLoading ||
          isActiveBoostersLoading
        }
        hideBackground
      />
      <Notification
        isOpen={isCoinsStatusError || isBoostersListError}
        type="error"
        title="Произошла ошибка"
        text={
          coinsStatusError
            ? (coinsStatusError as any)?.text ||
              (boostersListStatusError as any)?.text
            : 'Error'
        }
      />
      <ConfirmModal
        isOpen={!!selectedBooster}
        onConfirm={handleConfirmClick}
        onReject={handleRejectClick}
        booster={selectedBooster || ({} as BoosterData)}
      />
    </AppLayout>
  );
};

export default ShopPage;
