// Notification.tsx
import React, { useEffect, useState } from 'react';
import './styles.scss';

interface Props {
  isOpen: boolean;
  type: 'error' | 'info';
  title: string;
  text: string;
  onClose?: () => void;
  autoCloseDuration?: number;
}

const Notification: React.FC<Props> = ({
  isOpen,
  type = 'info',
  title,
  text,
  onClose,
  autoCloseDuration = 5000,
}) => {
  const [isOpenState, setIsOpenState] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen && autoCloseDuration > 0) {
      timer = setTimeout(() => {
        setIsOpenState(false);
        if (onClose) onClose();
      }, autoCloseDuration);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen, autoCloseDuration, onClose]);

  const handleClose = () => {
    setIsOpenState(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setIsOpenState(isOpen);
  }, [isOpen]);

  if (!isOpenState) {
    return null;
  }

  return (
    <div className={`notification ${type}`}>
      <div className="notification-header">
        <h3>{title}</h3>
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
      </div>
      <div className="notification-content">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Notification;
