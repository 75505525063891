import React, { useEffect, useState } from 'react';
import './styles.scss';
import Modal from '../../../../components/Modal';
import { DailyBonusData } from '../../../../types/Coin';
import dailyRewardsImage from '../../../../assets/images/dailyRewards.png';
import coinImage from '../../../../assets/images/coin.png';

interface Props {
  dailyBonusData?: DailyBonusData;
}

const DailyBonusModal: React.FC<Props> = ({ dailyBonusData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (dailyBonusData) {
      return openModal();
    }
    closeModal();
  }, [dailyBonusData]);

  if (isModalOpen) {
    return (
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="daily-reward__title">Daily reward!</h2>
        <div className="daily-reward__main-icon-wrapper">
          <img
            src={dailyRewardsImage}
            className="daily-reward__main-icon"
            alt="daily-reward"
          />
        </div>

        <p className="daily-reward__day">Day {dailyBonusData?.day || 1}</p>
        <p className="daily-reward__reward">
          <img src={coinImage} className="daily-reward__icon" alt="coin" />
          {dailyBonusData?.bonus || 0}
        </p>
      </Modal>
    );
  }
  return null;
};

export default DailyBonusModal;
