import React from 'react';
import './styles.scss';
import coinImage from '../../../../assets/images/coin.png';

interface Props {
  referrals: number;
  rewards: number;
}

const InviteStatistics: React.FC<Props> = ({ rewards = 0, referrals = 0 }) => {
  return (
    <div className="invite-statistics">
      <div className="invite-statistics__item">
        <span>Referrals </span>
        <span>{referrals || 0}</span>
      </div>
      <div className="invite-statistics__item">
        <span>Rewards</span>
        <span>
          {rewards || 0}
          <img
            src={coinImage}
            className="coin-button__collect-icon"
            alt="coin"
          />
        </span>
      </div>
    </div>
  );
};

export default InviteStatistics;
