import React from 'react';
import './styles.scss';
import { PriceType } from '../../../../types/Coin';
import coinImage from '../../../../assets/images/coin.png';
import TONImage from '../../../../assets/images/TON.png';
import VideoImage from '../../../../assets/images/video.png';
import TgImage from '../../../../assets/images/tg.png';
import FriendsImage from '../../../../assets/images/friends-earn.png';
import { MissionData, MissionType } from '../../../../types/Mission';

interface Props {
  onClick: (missionId: number) => void;
  mission: MissionData;
  disabled: boolean;
}

const Mission: React.FC<Props> = ({ mission, onClick, disabled }) => {
  const { type, title, subtitle, price, priceType } = mission || {};

  const mainIcon: Record<MissionType, string> = {
    video: VideoImage,
    tg: TgImage,
    invite: FriendsImage,
  };

  const priceIcon: Record<PriceType, string> = {
    coins: coinImage,
    TON: TONImage,
  };

  return (
    <div
      data-disabled={disabled}
      data-completed={!!mission?.isCompleted}
      className="mission__wrapper"
      onClick={() => onClick(mission?.id || 1)}
    >
      <img
        src={mainIcon?.[type]}
        className="mission__icon"
        alt="booster-icon"
      />
      <div className="mission__description">
        <h3 className="mission__title">{title}</h3>
        <p className="mission__subtitle">{subtitle}</p>
      </div>
      <div className="mission__price">
        <img src={priceIcon?.[priceType]} alt="booster-price-icon" />
        <p>{price || 0}</p>
      </div>
    </div>
  );
};

export default Mission;
