import axios, { AxiosError, AxiosInstance } from 'axios';
import store from '../../store/store';
import { setError, setIsAuth } from '../../store/authSlice';

const instance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT || '10000', 10),
});

instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(setIsAuth(false));
      store.dispatch(setError('Не авторизован'));
    }
    return Promise.reject(error);
  },
);

export default instance;
