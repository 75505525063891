import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showCloseButton?: boolean; // Пропс для показа/скрытия кнопки закрытия
  className?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  showCloseButton = true,
  className = '',
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`modal-overlay ${className}`} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {showCloseButton && (
          <button className="close-button" onClick={onClose}>
            ✖
          </button>
        )}
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
