// Menu.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { MenuItem } from './types';
import { DEFAULT_MENU_ITEMS } from './constants';

interface Props {
  menuItems?: MenuItem[];
}

const Menu: React.FC<Props> = ({ menuItems = DEFAULT_MENU_ITEMS }) => {
  return (
    <nav className="menu">
      <ul className="menu__wrapper">
        {menuItems?.map((item: MenuItem) => (
          <li key={item?.id} className="menu__item">
            <Link
              to={`${item?.url}`}
              className="menu__item-title"
              onClick={() =>
                window?.Telegram?.WebApp?.HapticFeedback?.impactOccurred(
                  'medium',
                )
              }
            >
              <img src={item?.icon} className="menu__item-icon" alt="logo" />
              {item?.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
