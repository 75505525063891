import { useEffect, useState } from 'react';

export const useNoise = () => {
  const [showNoise, setShowNoise] = useState(false);

  useEffect(() => {
    const toggleNoise = () => {
      setShowNoise(true);
      setTimeout(() => {
        setShowNoise(false);
        scheduleNextNoise();
      }, 500);
    };

    const scheduleNextNoise = () => {
      const randomDelay = Math.random() * (15000 - 10000) + 5000; // Рандом от 5 до 15 секунд
      setTimeout(toggleNoise, randomDelay);
    };

    scheduleNextNoise(); // Запускаем цикл появления шума
  }, []);

  return { showNoise };
};
