import React from 'react';
import './styles.scss';
import AppLayout from '../../components/AppLayout';
import BackButton from '../../components/BackButton';
import earnIcon from '../../assets/images/earn.png';
import Mission from './components/Mission';
import { MissionData } from '../../types/Mission';
import { useMutation, useQuery } from 'react-query';
import { getEarns, handleMissionClick } from '../../services/earns';
import Spinner from '../../components/Spinner';
import { buyBooster } from '@/services/shop';

const EarnPage: React.FC = () => {
  const {
    data: earnsList,
    isLoading: isEarnsListLoading,
    error: earnsListStatusError,
    isError: isEarnsListError,
    refetch: refetchEarnList,
  } = useQuery('getEarns', getEarns, {
    enabled: true,
    retry: false,
  });

  const { mutate: handleMissionButtonClick, isLoading: isBoosterBuyFetching } =
    useMutation((misson: MissionData) => handleMissionClick(misson?.id), {
      onSuccess: () => {
        refetchEarnList();
      },
    });

  return (
    <AppLayout>
      <BackButton />
      <main className="earn__main">
        <div className="earn__top-wrapper">
          <img src={earnIcon} className="earn__image" alt="earn" />
          <h2 className="earn__title">Complete missions to Earn </h2>
          <div className="earn__missions">
            {Array.isArray(earnsList) &&
              (earnsList as MissionData[])?.map((mission) => (
                <Mission
                  key={mission.id}
                  onClick={handleMissionClick}
                  mission={mission}
                  disabled={!!mission.disabled || !!mission?.isCompleted}
                />
              ))}
          </div>
        </div>
      </main>
      <Spinner isShow={isEarnsListLoading} hideBackground />
    </AppLayout>
  );
};

export default EarnPage;
