import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CoinsState {
  coins: number;
}

const initialState: CoinsState = {
  coins: 0,
};

const authSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    setCoins(state, action: PayloadAction<number | null>) {
      state.coins = action.payload || 0;
    },
  },
});

export const { setCoins } = authSlice.actions;

export default authSlice.reducer;
