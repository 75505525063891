import { useEffect, useState } from 'react';
import { CoinButtonProps } from './index';

import { calculateRemainingTime } from './helpers';
import { CalculateRemainingTimeReturnData } from './types';
import { useQuery } from 'react-query';
import { getCoinsConfig } from '../../../../services/coin';

export const useCoinButtonControl = ({
  onGetCoin,
  availableDate,
}: Partial<CoinButtonProps>) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const { isLoading: isCoinsConfigLoading, data: coinsConfig } = useQuery(
    'getCoinsConfig',
    getCoinsConfig,
    {
      enabled: true,
      retry: false,
    },
  );

  const [availableDateState, setAvailableDateState] =
    useState<CalculateRemainingTimeReturnData | null>(null);

  const handleButtonClick = () => {
    setIsDisabled(true);
    onGetCoin && onGetCoin();

    const timeout = setTimeout(
      () => {
        setIsDisabled(false);
      },
      Number(process.env.REACT_APP_API_TIMEOUT || 10000),
    );

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    if (!coinsConfig) {
      return;
    }

    let timeout: any = null;

    const updateTime = () => {
      if (availableDate) {
        const calculatedRemainingTime = calculateRemainingTime(
          availableDate,
          (coinsConfig as any)?.maxEarnedCoins,
          (coinsConfig as any)?.coinsPerHour,
        );

        setAvailableDateState(calculatedRemainingTime);
      }

      timeout = setTimeout(updateTime, 1000);
    };

    updateTime();

    return () => timeout && clearTimeout(timeout);
  }, [availableDate, coinsConfig]);

  return {
    availableDateState,
    coinsConfig,
    isDisabled,
    isCoinsConfigLoading,
    handleButtonClick,
  };
};
