// Menu.tsx
import React from 'react';
import './styles.scss';

interface Props {
  isShow: boolean;
  hideBackground?: boolean;
}

const Spinner: React.FC<Props> = ({ isShow, hideBackground = false }) => {
  if (!isShow) {
    return null;
  }
  return (
    <div className="spinner-overlay" data-hide-background={hideBackground}>
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
