import React, { ReactNode } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonProps } from '../Button';
import backImage from '../../assets/images/back.png';

interface Props extends ButtonProps {
  children: ReactNode;
}

const BackButton: React.FC<any> = ({ children, ...props }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="icon"
      onClick={() => navigate('/')}
      className="back-button"
      icon={backImage}
      {...props}
    >
      {/*<img src={backImage} className="back-button__icon" alt="logo" />*/}
      {children}
    </Button>
  );
};

export default BackButton;
